import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { green } from "@mui/material/colors"; // Use green for positive balance
import ApiService from "../lib/api_service";
import { formatCurreny } from "../lib/convertAmount";
import { useSelector } from "react-redux";

const api = new ApiService();
const UserBalance = ({onClick}) => {
  const [balances, setBalances] = useState(0);
  const { user } = useSelector((st) => st.user);

  useEffect(() => {
    fetchBalance();
  }, []);
  const fetchBalance = async () => {
    const res = await api.getRequest("api/v1/accounts/balances", user.token);
    if (!res.status) {
      return;
    }
    setBalances(res.data.balance);
  };
  return (
    <Box
    onClick={onClick}
      sx={{
        display: "flex",
        alignItems: "center",
        padding: 1,
        borderRadius: 2,
        backgroundColor: "background.paper",
        boxShadow: 2,
        minWidth: 120,
        justifyContent: "center",
        cursor:"pointer"
      }}
    >
      <AccountBalanceWalletIcon
        sx={{ color: green[500], fontSize: 30 }} // Larger icon, green color
      />
      <Typography
        variant="h6"
        sx={{
          fontWeight: "bold",
          color: "text.primary",
          display: "flex",
          alignItems: "center",
        }}
      >
        {formatCurreny({ amount: balances, currency: "KES" })}
      </Typography>
    </Box>
  );
};

export default UserBalance;
