import {
  Alert,
  Backdrop,
  Box,
  Button,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { animated, useSpring } from "@react-spring/web";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import ApiService from "../lib/api_service";
import { DatePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";

const Fade = React.forwardRef(function Fade(props, ref) {
  const {
    children,
    in: open,
    onClick,
    onEnter,
    onExited,
    ownerState,
    ...other
  } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {React.cloneElement(children, { onClick })}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element.isRequired,
  in: PropTypes.bool,
  onClick: PropTypes.any,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
  ownerState: PropTypes.any,
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};
const api = new ApiService();
const CreateCampaignModal = ({ open, onClose, refetch }) => {
  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const { user } = useSelector((st) => st.user);

  const handleCreateCampaign = async () => {
    const formattedStartDate = startDate
      ? DateTime.fromJSDate(startDate).toISO()
      : null; // Convert to ISO string
    const formattedEndDate = DateTime.fromJSDate(endDate).toISO();

    if (!endDate) {
      setError("End Date is required");
      setTimeout(() => {
        setError(null);
      }, 4000);
      return;
    }
    api
      .postRequestwithBearer(
        "api/v1/campaigns/create",
        JSON.stringify({
          title,
          description,
          startDate: startDate, // Send as ISO string
          endDate: endDate,
        }),
        user.token
      )
      .then((response) => {
        if (!response.status) {
          setError(response.message);
          setTimeout(() => {
            setError(null);
          }, 4000);
          return;
        }
        refetch();
        onClose();
      })
      .catch((error) => {
        console.error("Error creating campaign:", error);
      });
  };
  console.log("END DATE", endDate, "DATE", startDate);
  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          TransitionComponent: Fade,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Typography variant="h6">Create New Campaign</Typography>
          {/* <TextField
            label="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            fullWidth
            margin="normal"
          /> */}
          <TextField
            label="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            fullWidth
            margin="normal"
            multiline
            minRows={4}
          />
          <DatePicker
            label="Start Date (Optional)"
            value={startDate}
            onChange={(newValue) => setStartDate(newValue)}
            renderInput={(params) => (
              <TextField {...params} fullWidth margin="normal" />
            )}
            sx={{
              width: "100%",
              marginTop: 2,
            }}
          />
          <DatePicker
            label="End Date"
            value={endDate}
            onChange={(newValue) => setEndDate(newValue)}
            renderInput={(params) => (
              <TextField {...params} fullWidth margin="normal" />
            )}
            sx={{
              width: "100%",
              marginTop: 2,
            }}
          />
          {error && (
            <Box mb={2}>
              <Alert severity="error">{error}</Alert>
            </Box>
          )}
          <Button
            variant="contained"
            onClick={handleCreateCampaign}
            sx={{ marginTop: 2 }}
          >
            Create
          </Button>
        </Box>
      </Fade>
    </Modal>
  );
};

export default CreateCampaignModal;
