import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import BlockUser from "../../components/BlockUser";
import BooleanChip from "../../components/BooleanChip";
import ApiService from "../../lib/api_service";

const AdminUsers = () => {
  const api = new ApiService();
  const { user } = useSelector((st) => st.user);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const fetchData = async (page, pageSize, searchTerm) => {
    setLoading(true);
    try {
      const response = await api.getRequest("api/v1/users", user.token, {
        page: page + 1,
        limit: pageSize,
        search: searchTerm,
      });

      if (response.status) {
        setData(response.data.users);
        setTotalRows(response.meta.totalItems);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(paginationModel.page, paginationModel.pageSize, searchTerm);
  }, [paginationModel, searchTerm]);

  const handleViewClick = (transaction) => {
    console.log("Viewing transaction:", transaction);
  };

  const handleUpdateClick = (transaction) => {
    setOpen(!open);
  };

  // Define columns
  const columns = [
    { field: "name", headerName: "Name", flex: 1 },
    { field: "email", headerName: "Email", flex: 1 },
    { field: "mobileNumber", headerName: "Phone Number", flex: 1 },
    { field: "balance", headerName: "Balance", flex: 1 },
    {
      field: "isBlocked",
      headerName: "Blocked",
      flex: 1,
      renderCell: (params) => (
        <BlockUser
          row={params.row}
          refetchUser={() =>
            fetchData(
              paginationModel.page,
              paginationModel.pageSize,
              searchTerm
            )
          }
        />
      ),
    },
    {
      field: "isVerified",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => <BooleanChip value={params.row.isVerified} />,
    },
    { field: "totalTransactions", headerName: "Transactions", flex: 1 },
    { field: "referralEarnings", headerName: "Referral", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      flex: 1,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<VisibilityIcon />}
          label="View"
          onClick={() => handleViewClick(params.row)}
        />,
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Update"
          onClick={() => handleUpdateClick(params.row)}
        />,
      ],
    },
  ];
  const onFilterChange = useCallback((filterModel) => {
    if (filterModel.quickFilterValues.length > 0) {
      setSearchTerm(filterModel.quickFilterValues[0] || "");
    } else {
      setSearchTerm("");
    }
  }, []);

  return (
    <div style={{ width: "100%" }}>
      <DataGrid
        sx={{ width: "100%" }}
        rows={data}
        columns={columns}
        pagination
        pageSizeOptions={[10, 25, 50, 100]}
        paginationModel={paginationModel}
        rowCount={totalRows}
        paginationMode="server"
        filterMode="server"
        disableColumnFilter
        filterDebounceMs={500}
        disableColumnSelector
        disableDensitySelector
        loading={loading}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        onFilterModelChange={onFilterChange}
        onPaginationModelChange={setPaginationModel}
      />
    </div>
  );
};

export default AdminUsers;
