import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Card,
  Checkbox,
  Container,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../components/Logo";
import ApiService from "../../lib/api_service";
import { loginUser } from "../../store/actions/auth";

const api = new ApiService();

export default function SignIn() {
  const [login, setLogin] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState(null);
  const [visible, setVisible] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const response = await api.postRequest(
      `api/v1/auth/signIn`,
      JSON.stringify(login)
    );
    if (!response.status) {
      setError(response.message);
      setTimeout(() => {
        setError(null);
      }, 4000);
      return;
    }
    let token = response.data.token;
    const me = response.data.user;
    const usr = { ...me, token: token, isVerified: true };
    dispatch(loginUser(usr));
    if (me.role.name === "USER") {
      navigate("/user/signals");
    } else {
      navigate("/admin/dashboard");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLogin({ ...login, [name]: value });
  };

  return (
    <Container
      maxWidth="xs"
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        px: 2,
      }}
    >
      <Box
        component="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          px: 2,
          py: 4,
          // backgroundColor: "white",
          borderRadius: 2,

          width: "100%",
        }}
      >
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            flexGrow: 1,
            p: 1,
          }}
        >
          {" "}
          <Box mb={2}>
            <Logo />
          </Box>
          <Typography variant="h5" gutterBottom>
            Login to your account
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              variant="outlined"
              label="Email"
              name="email"
              value={login.email}
              onChange={handleChange}
              required
              margin="normal"
            />

            <TextField
              fullWidth
              variant="outlined"
              label="Password"
              name="password"
              type={visible ? "text" : "password"}
              value={login.password}
              onChange={handleChange}
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setVisible(!visible)} edge="end">
                      {visible ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={rememberMe}
                    onChange={(event) => setRememberMe(event.target.checked)}
                    color="primary"
                  />
                }
                label="Remember me"
              />
              <Link to="/verify-email" style={{ textDecoration: "none" }}>
                <Typography variant="body2" color="primary">
                  Forgot password?
                </Typography>
              </Link>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
            >
              <Link to="/signup" style={{ textDecoration: "none" }}>
                <Typography variant="body2" color="primary">
                  Create account?
                </Typography>
              </Link>
            </Box>
            {error && (
              <Box mb={2}>
                <Alert severity="error">{error}</Alert>
              </Box>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{
                padding: "12px 0",
                fontSize: "16px",
                textTransform: "uppercase",
              }}
            >
              Sign In
            </Button>
          </form>
        </Card>
      </Box>
    </Container>
  );
}
