import React, { useEffect, useState } from "react";
import CreateCampaignModal from "../../components/CreateCampaignModal";
import { Box, Button, Grid, Typography } from "@mui/material";
import CampaignCard from "../../components/CampaignCard";
import { useSelector } from "react-redux";
import ApiService from "../../lib/api_service";

const api = new ApiService();
export default function Campaigns() {
  const [campaigns, setCampaigns] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const { user } = useSelector((st) => st.user);

  useEffect(() => {
    fetchCampaigns();
  }, []);
  const fetchCampaigns = async () => {
    try {
      const response = await api.getReq("api/v1/campaigns", user.token);
      if (response.status) {
        setCampaigns(response.data);
      }
    } catch (error) {
      console.error("Error fetching agents:", error);
    }
  };
  const handleDelete = (campaignId) => {
    setCampaigns(
      campaigns.filter(({ campaign }) => campaign.id !== campaignId)
    );
  };

  return (
    <div>
      {" "}
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          marginTop: 2,
          marginBottom: 2,
        }}
      >
        {" "}
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpenModal(true)}
        >
          Create Campaign
        </Button>
      </Box>
      <Typography variant="h6" gutterBottom>
        Latest Campaigns
      </Typography>
      <Grid container spacing={2}>
        {campaigns.map(({ campaign, closedCount }) => (
          <Grid item xs={12} sm={12} md={6} key={campaign.id}>
            <CampaignCard
              campaign={{ ...campaign, closedCount }}
              onDelete={handleDelete}
            />
          </Grid>
        ))}
      </Grid>
      <CreateCampaignModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        refetch={fetchCampaigns}
      />
    </div>
  );
}
