import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import BooleanChip from "../../components/BooleanChip";
import ApiService from "../../lib/api_service";
import {
  Box,
  Button,
  Card,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import UserAvatar from "../../components/UserAvatar";
import { Delete, Edit } from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const api = new ApiService();
const UserAdmins = () => {
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState([]);
  const [roles, setRoles] = useState([]);

  // const [transaction, setTransaction] = useState({});
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [loading, setLoading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [editMode, setEditMode] = useState(false);

  const { user } = useSelector((st) => st.user);
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    role: "",
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const fetchData = async (page, pageSize, searchTerm) => {
    setLoading(true);
    try {
      const response = await api.getRequest("api/v1/users/admins", user.token, {
        page: page + 1,
        limit: pageSize,
        search: searchTerm,
      });

      setData(response.data.users);
      setTotalRows(response.data.totalItems);
    } catch (error) {
      console.error("Error fetching transactions data:", error);
    } finally {
      setLoading(false);
    }
  };
  const fetchRoles = async () => {
    setLoading(true);
    try {
      const response = await api.getRequest("api/v1/roles", user.token);
      console.log("RES", response);
      setRoles(response.data.roles);
    } catch (error) {
      console.error("Error fetching transactions data:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleSubmit = async () => {
    try {
      if (editMode) {
        await api.putRequestwithBearer(
          `api/v1/agents/`,
          JSON.stringify(formData),
          user.token
        );
      } else {
        await api.postRequestwithBearer(
          "api/v1/users/create-user",
          JSON.stringify(formData),
          user.token
        );
      }
      fetchData();
      setOpenModal(false);
      setFormData({
        firstname: "",
        lastname: "",
        email: "",
        password: "",
        role: "",
      });
      setEditMode(false);
    } catch (error) {
      console.error("Error saving agent:", error);
    }
  };
  useEffect(() => {
    fetchData(page, pageSize, searchTerm);
    fetchRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize, searchTerm]);

  const handleViewClick = (transaction) => {
    // Implement the view action
    console.log("Viewing transaction:", transaction);
  };

  const handleUpdateClick = (transaction) => {
    // Implement the update action
    // setTransaction(transaction);
    handleClose();
  };

  const columns = [
    // { field: "id", headerName: "ID", width: 100 },
    {
      field: "name",
      headerName: "Name",
      editable: false,
      flex: 1,
      renderCell: (params) => <UserAvatar user={params.row} />,
    },
    {
      field: "email",
      headerName: "Email",
      editable: false,
      flex: 1,
    },
    {
      field: "mobileNumber",
      headerName: "Phone Number",
      editable: false,
      flex: 1,
    },
    {
      field: "isBlocked",
      headerName: "Blocked",
      editable: false,
      flex: 1,
      renderCell: (params) => <BooleanChip value={params.row.isBlocked} />,
    },
    {
      field: "isVerified",
      headerName: "Status",
      editable: false,
      flex: 1,
      renderCell: (params) => <BooleanChip value={params.row.isVerified} />,
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      editable: false,
      flex: 1,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<Edit />}
          label="Edit"
          onClick={() => handleViewClick(params.row)}
        />,
        <GridActionsCellItem
          icon={<Delete />}
          label="Delete"
          onClick={() => handleDelete(params.row.id)}
        />,
      ],
    },
  ];
  const handleClose = () => setOpen(!open);
  const handleEdit = (admin) => {
    setSelectedAdmin(admin);
    setFormData(admin);
    setEditMode(true);
    setOpenModal(true);
  };

  const handleDelete = async (id) => {
    try {
      await api.deleteRequest(`api/v1/agents/${id}`);
      fetchData();
    } catch (error) {
      console.error("Error deleting agent:", error);
    }
  };
  return (
    <div style={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          marginTop: 2,
          marginBottom: 2,
        }}
      >
        {" "}
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpenModal(true)}
        >
          Create Admin
        </Button>
      </Box>
      <Card
        variant="outlined"
        sx={{
          height: "100%",
          width: "100%",
          display: { xs: "none", sm: "flex" },
          pointerEvents: "none",
        }}
      >
        <DataGrid
          editMode="row"
          rows={data}
          columns={columns}
          pagination
          page={page}
          pageSize={pageSize}
          rowsPerPageOptions={[5, 10, 25]}
          rowCount={totalRows}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          sx={{ width: "100%" }}
          paginationMode="server"
          onPageChange={(newPage) => setPage(newPage)}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          loading={loading}
          components={{
            Toolbar: GridToolbar,
          }}
        />
      </Card>
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box sx={style}>
          <Typography variant="h6" gutterBottom>
            {editMode ? "Edit Agent" : "Add Agent"}
          </Typography>
          <TextField
            fullWidth
            margin="normal"
            label="First Name"
            name="firstname"
            value={formData.firstname}
            onChange={handleInputChange}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Last Name"
            name="lastname"
            value={formData.lastname}
            onChange={handleInputChange}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Password"
            name="password"
            type="password"
            value={formData.password}
            onChange={handleInputChange}
          />
          <FormControl
            fullWidth
            size="small"
            sx={{ marginTop: 1, marginBottom: 1 }}
          >
            <InputLabel id="demo-simple-select-label">Role</InputLabel>
            <Select
              name="role"
              labelId="Role"
              id="role"
              value={formData.type}
              label="User Role"
              onChange={handleInputChange}
            >
              {roles.map((role) => (
                <MenuItem key={role.id} value={role.id}>
                  {role.name}
                </MenuItem>
              ))}
              {/* <MenuItem value="deposit">Deposit</MenuItem>
              <MenuItem value="withdraw">Withdraw</MenuItem> */}
            </Select>
          </FormControl>

          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            sx={{ mt: 2 }}
          >
            {editMode ? "Update" : "Create"}
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default UserAdmins;
