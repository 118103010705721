import React from "react";
import {
  Box,
  Container,
  Typography,
  Button,
  Fab,
  CircularProgress,
} from "@mui/material";

export default function Loader() {
  return (
    <Container
      maxWidth="xs"
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        px: 2,
      }}
    >
      <Box
        component="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          px: 2,
          py: 4,

          width: "100%",
        }}
      >
        <CircularProgress />
      </Box>
    </Container>
  );
}
