import { Delete, Edit } from "@mui/icons-material";
import {
  Backdrop,
  Box,
  Button,
  Modal,
  TextField,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ApiService from "../../lib/api_service";
import { animated, useSpring } from "@react-spring/web";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};
const Fade = React.forwardRef(function Fade(props, ref) {
  const {
    children,
    in: open,
    onClick,
    onEnter,
    onExited,
    ownerState,
    ...other
  } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {React.cloneElement(children, { onClick })}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element.isRequired,
  in: PropTypes.bool,
  onClick: PropTypes.any,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
  ownerState: PropTypes.any,
};

const api = new ApiService();
const TimeIntervals = () => {
  const [agents, setAgents] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [openDelete, setOpenDelete] = useState(false); // Modal state
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [selectedInterval, setSelectedInterval] = useState(null);
  const user = useSelector((st) => st.user).user;
  const [formData, setFormData] = useState({
    name: "",
    period: "",
    rate: "",
    daily: true,
  });

  useEffect(() => {
    fetchAgents();
  }, []);

  const fetchAgents = async () => {
    try {
      const response = await api.getRequest("api/v1/timeframes", user.token);
      setAgents(response.data);
    } catch (error) {
      console.error("Error fetching agents:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    try {
      if (editMode) {
        await api.putRequestwithBearer(
          `api/v1/timeframes/${selectedInterval.id}`,
          JSON.stringify(formData),
          user.token
        );
      } else {
        await api.postRequestwithBearer(
          "api/v1/timeframes",
          JSON.stringify(formData),
          user.token
        );
      }
      fetchAgents();
      setOpenModal(false);
      setFormData({
        name: "",
        period: "",
        rate: "",
      });
      setEditMode(false);
    } catch (error) {
      console.error("Error saving agent:", error);
    }
  };

  const handleEdit = (agent) => {
    setSelectedInterval(agent);
    setFormData(agent);
    setEditMode(true);
    setOpenModal(true);
  };

  const handleDelete = async () => {
    try {
      const response = await api.deleteRequest(
        `api/v1/timeframes/${selectedInterval.id}`,
        user.token
      );
      if (response.status) {
        fetchAgents();
        handleDeleteModal();
      }
    } catch (error) {
      console.error("Error deleting agent:", error);
    }
  };

  const columns = [
    // { field: "id", headerName: "ID", minWidth: 150 },
    { field: "name", headerName: "Name", flex: 1, editable: false },
    { field: "period", headerName: "Time Interval", flex: 1, editable: false },
    { field: "rate", headerName: "Rates", flex: 1, editable: false },
    {
      field: "updatedAt",
      headerName: "Updated At",
      editable: false,
      flex: 1,
      renderCell: (params) =>
        DateTime.fromISO(params.row.updatedAt).toLocaleString(
          DateTime.DATETIME_MED
        ),
    },
    {
      field: "createdAt",
      headerName: "Created At",
      editable: false,
      flex: 1,
      renderCell: (params) =>
        DateTime.fromISO(params.row.createdAt).toLocaleString(
          DateTime.DATETIME_MED
        ),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      type: "actions",
      editable: false,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<Edit />}
          label="Edit"
          onClick={() => handleEdit(params.row)}
        />,
        <GridActionsCellItem
          icon={<Delete />}
          label="Delete"
          onClick={() => handleDeleteModal(params.row)}
        />,
      ],
    },
  ];
  const handleDeleteModal = (timeframe) => {
    setOpenDelete(!openDelete);
    setSelectedInterval(timeframe);
  };
  return (
    <div style={{}}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          marginTop: 2,
          marginBottom: 2,
        }}
      >
        {" "}
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpenModal(true)}
        >
          Add Time Interval
        </Button>
      </Box>

      <DataGrid
        rows={agents}
        editMode="row"
        pagination
        rowsPerPageOptions={[5, 10, 25]}
        columns={columns}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        sx={{ width: "100%" }}
      />

      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box sx={style}>
          <Typography variant="h6" gutterBottom>
            {editMode ? "Edit Agent" : "Add Agent"}
          </Typography>
          <TextField
            fullWidth
            margin="normal"
            label="Name"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Period"
            name="period"
            placeholder="60"
            value={formData.period}
            onChange={handleInputChange}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Rate"
            name="rate"
            placeholder="0.08"
            value={formData.rate}
            onChange={handleInputChange}
          />
          {/* add checkbox to mark daily either true or false */}
          <div>
            {" "}
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.daily || false}
                  onChange={(e) =>
                    handleInputChange({
                      target: { name: "daily", value: e.target.checked },
                    })
                  }
                  name="daily"
                />
              }
              label="Daily"
            />
          </div>

          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            sx={{ mt: 2 }}
          >
            {editMode ? "Update" : "Create"}
          </Button>
        </Box>
      </Modal>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={openDelete}
        onClose={handleDeleteModal}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
      >
        <Fade in={openDelete}>
          <Box sx={style}>
            {" "}
            <Typography
              id="spring-modal-title"
              variant="h6"
              component="h2"
              color="error.main"
            >
              Time Interval Delete
            </Typography>
            <Typography
              id="spring-modal-title"
              variant="subtitle2"
              component="h2"
              color="error.main"
            >
              Are you sure you want to delete?
            </Typography>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "end",
                marginTop: 2,
              }}
            >
              <Button
                onClick={handleDeleteModal}
                color="secondary"
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                onClick={handleDelete}
                color="error"
                variant="outlined"
                sx={{ ml: 2 }}
              >
                Delete
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default TimeIntervals;
