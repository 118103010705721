import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  IconButton,
  LinearProgress,
} from "@mui/material";
import { AttachMoney, TrendingUp, Delete } from "@mui/icons-material";
import React from "react";
import { useSelector } from "react-redux";

export default function TradeCard({ investment, onInvest, onDelete }) {
  const { user } = useSelector((state) => state.user);

  return (
    <Card
      variant="elevation"
      sx={{
        p: 1,
        width: "100%",
        maxWidth: { xs: "100%", sm: "100%", md: "90%" },
        backgroundColor: "background.paper",
        borderRadius: 3,
        boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
        transition: "transform 0.2s",
        "&:hover": {
          transform: "scale(1.05)",
        },
      }}
    >
      <CardContent>
        <Box display="flex" alignItems="center" mb={2}>
          <AttachMoney sx={{ fontSize: 32, color: "#4caf50", mr: 1 }} />
          <Typography variant="h6" component="div" sx={{ fontWeight: "bold" }}>
            {investment.name}
          </Typography>
        </Box>

        <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
          Deposit Amount: KES {investment.amount}
        </Typography>

        <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
          Expected Returns: KES {investment?.returns} in{" "}
          {Number.parseInt(investment.timeframe.period)} days
        </Typography>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 2 }}
        >
          <Typography variant="body2" color="text.secondary">
            Progress
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {((investment.timeframe.period / investment.timeframe.period) * 100).toFixed(0)}%
          </Typography>
        </Box>

        <LinearProgress
          variant="determinate"
          value={(investment.timeframe.period / investment.timeframe.period) * 100}
          sx={{
            height: 8,
            borderRadius: 4,
            "& .MuiLinearProgress-bar": {
              backgroundColor: "#4caf50",
            },
          }}
        />

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mt={3}
        >
          {user?.role?.name === "USER" ? (
            <Button
              variant="contained"
              color="success"
              sx={{
                fontWeight: "bold",
                borderRadius: "20px",
                paddingX: 3,
                boxShadow: "0 3px 10px rgba(76, 175, 80, 0.3)",
                "&:hover": {
                  backgroundColor: "#388e3c",
                },
              }}
              onClick={() => onInvest(investment)}
            >
              Invest Now
            </Button>
          ) : (
            <IconButton color="error" onClick={() => onDelete(investment)}>
              <Delete />
            </IconButton>
          )}
        </Box>
      </CardContent>
    </Card>
  );
}
