import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from 'firebase/messaging';


const firebaseConfig = {
    apiKey: "AIzaSyCTBe_DFR_CSG0VdU9J8_E9yNWQrb8LFD4",
    authDomain: "forex-pro-95e7c.firebaseapp.com",
    projectId: "forex-pro-95e7c",
    storageBucket: "forex-pro-95e7c.appspot.com",
    messagingSenderId: "909163734663",
    appId: "1:909163734663:web:9978c8abf6d79aec15fa22",
    measurementId: "G-RLJPKTPH2P"
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const requestNotificationPermission = async () => {
    try {
        const token = await getToken(messaging, {
            vapidKey: 'BH1Mh9gw68zYporWeerCa_yutGaSWSsJWkFOymOiQmk70bxgp_AjREOqfKSEajOBLURIWYxcimoFuhBqmzxbv-g',

        });
        if (token) {
            console.log('Notification Token:', token);
            return token;
        } else {
            console.log('No notification token found');
        }
    } catch (error) {
        console.error('Error while fetching token', error);
    }
};

// onMessage(messaging, (payload) => {
//     console.log('Message received:', payload);
// });