import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import AdminDashboardLayout from "./layouts/AdminLayout";
import UserDashboardLayout from "./layouts/UsersLayout";
import { isTokenExpired } from "./lib/auth/check_token_expiration";
import ProtectedRoute from "./lib/AuthHook";
import AdminDeposits from "./pages/Admin/AdminDeposit";
import AdminProfile from "./pages/Admin/AdminProfile";
import UserAdmins from "./pages/Admin/Admins";
import AdminTrades from "./pages/Admin/AdminTrades";
import AdminWithdrawals from "./pages/Admin/AdminWithdrawals";
import AgentsManagement from "./pages/Admin/AgentsManagement";
import Dashboard from "./pages/Admin/Dashboard";
import SignalsPageAdmin from "./pages/Admin/Signals";
import TimeIntervals from "./pages/Admin/TimeFrames";
import AdminUsers from "./pages/Admin/Users";
import ConfirmOtp from "./pages/Auth/ConfirmOtp";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import Signin from "./pages/Auth/Signin";
import Signup from "./pages/Auth/Signup";
import VerifyEmail from "./pages/Auth/VerifyEmail";
import HomePage from "./pages/HomePage";
import UserDeposits from "./pages/User/Deposits";
import UserProfile from "./pages/User/Profile";
import ReferralPage from "./pages/User/Referals";
import SignalDetailPage from "./pages/User/SignalDetail";
import SignalsPage from "./pages/User/Signals";
import UserWithrawals from "./pages/User/Withrawals";
import { logOut, setFCMToken } from "./store/actions/auth";
import Blocked from "./pages/Auth/Blocked";
import { requestNotificationPermission } from "./lib/firebase";
import ApiService from "./lib/api_service";
import Campaigns from "./pages/Admin/Campaigns";

const api = new ApiService()

function App() {
  const { user, fcmToken } = useSelector((st) => st.user);
  const dispatch = useDispatch();
  // useEffect(() => {
  //   if (user?.token && isTokenExpired(user?.token)) {
  //     dispatch(logOut());
  //   }
  // }, [user]);
  useEffect(() => {
    if (!fcmToken && user.token) {
      getNotificationToken();
    }
  }, [fcmToken, user]);

  const getNotificationToken = async () => {
    const token = await requestNotificationPermission();
    if (token) {
      dispatch(setFCMToken(token))
      await sendNotificationTokenToBackend(token);
    }
  };

  const sendNotificationTokenToBackend = async (token) => {
    try {
      const response = await api.postRequestwithBearer("api/v1/accounts/push/enable", JSON.stringify({
        notification_token: token,
        device_type: 'web',
      }), user.token
      );

      if (response.status) {
        console.log('Token successfully sent to the server');
      } else {
        console.error('Failed to send token to the server');
      }
    } catch (error) {
      console.error('Error sending notification token', error);
    }
  };
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route
          path="/admin"
          element={
            <ProtectedRoute allowedRoles={['ADMIN', 'SUPERADMIN']}>
              <AdminDashboardLayout />
            </ProtectedRoute>
          }
        >
          <Route index element={<Navigate to="dashboard" />} />
          <Route index path="dashboard" element={<Dashboard />} />
          <Route path="signals" element={<SignalsPageAdmin />} />
          <Route path="trades" element={<AdminTrades />} />
          <Route path="intervals" element={<TimeIntervals />} />
          <Route index path="deposits" element={<AdminDeposits />} />
          <Route index path="users" element={<AdminUsers />} />
          <Route path="profile" element={<AdminProfile />} />
          <Route path="adminstrators" element={<UserAdmins />} />
          <Route path="withdrawals" element={<AdminWithdrawals />} />
          <Route path="agents" element={<AgentsManagement />} />
          <Route path="campaigns" element={<Campaigns />} />
        </Route>
        <Route
          path="/user"
          element={
            <ProtectedRoute allowedRoles={['USER']}>
              <UserDashboardLayout />
            </ProtectedRoute>
          }
        >
          <Route index element={<Navigate to="signals" />} />
          <Route path="signals" element={<SignalsPage />} />
          <Route path="settings" element={<UserProfile />} />
          <Route path="referrals" element={<ReferralPage />} />
          <Route path="withdrawals" element={<UserWithrawals />} />
          <Route path="deposits" element={<UserDeposits />} />{" "}
          <Route path="signals/:id" element={<SignalDetailPage />} />
        </Route>
        <Route path="/signin" element={<Signin />} />
        <Route path="/verify-email" element={<VerifyEmail />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/reset-password" element={<ForgotPassword />} />
        <Route path="/confirm-otp" element={<ConfirmOtp />} />
        <Route path="/blocked" element={<Blocked />} />

      </Routes>
    </BrowserRouter>
  );
}

export default App;
