export const loginUser = (data) => {
	return (dispatch) => dispatch({ data, type: "LOGIN_USER" });
};
export const updateUser = (data) => {
	return (dispatch) => dispatch({ data, type: "UPDATE_USER" });
};
export const setFCMToken = (data) => {
	return (dispatch) => dispatch({ data, type: "SET_FCM" });
};
export const logOut = () => {
	return (dispatch) => dispatch({ type: "LOGOUT_USER" });
};
