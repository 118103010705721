import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { animated, useSpring } from "@react-spring/web";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import TradingSignalCard from "../../components/TradingSignal";
import ApiService from "../../lib/api_service";
import CoreChart from "../../components/Chart";
import TradeCard from "../../components/TradeCard";

const Fade = React.forwardRef(function Fade(props, ref) {
  const {
    children,
    in: open,
    onClick,
    onEnter,
    onExited,
    ownerState,
    ...other
  } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {React.cloneElement(children, { onClick })}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element.isRequired,
  in: PropTypes.bool,
  onClick: PropTypes.any,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
  ownerState: PropTypes.any,
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const api = new ApiService();

const SignalsPage = () => {
  const [error, setError] = useState(null);
  const [signals, setSignals] = useState([]);
  const [open, setOpen] = useState(false);
  const [signal, setSignal] = useState(null);
  const [loading, setLoading] = useState(true);
  const [activeTrade, setActiveTrade] = useState(null);
  const [amount, setAmount] = useState("");
  const [signalId, setSignalId] = useState("");

  const { user } = useSelector((st) => st.user);
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
    fetchActiveTrade();
  }, []);

  const fetchActiveTrade = async () => {
    setLoading(true);
    try {
      const response = await api.getRequest("api/v1/plans/open", user.token);
      if (response.statusCode) {
        if (!isEmpty(response.data)) {
          let data = response.data;
          data.direction = "up";
          setActiveTrade(response.data);
        } else {
          setActiveTrade(null); // or handle no trade accordingly
        }
      }
    } catch (error) {
      console.error("Error fetching signals data:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await api.getRequest("api/v1/plan-signals", user.token);
      setSignals(response.data.signals);
    } catch (error) {
      console.error("Error fetching signals data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (signal) => {
    try {
      const res = await api.postRequestwithBearer(
        "api/v1/plans",
        JSON.stringify({
          amount: signal.amount,
          planId: signal.id,
        }),
        user.token
      );
      if (!res.status) {
        setError(res.message);
        setTimeout(() => {
          setError(null);
        }, 4000);
        return;
      }
      setOpen(false); // Close the modal after adding the signal
      fetchData();
      setActiveTrade(true);
    } catch (error) {
      console.error("Error adding new signal:", error);
    }
  };
  const handleBuySignal = (signal) => {
    // navigate(`/user/signals/${signal.symbol}`, { state: { signal } });
    setOpen(!open);
    setSignalId(signal.id);
    setSignal(signal);
  };
  const handleClose = () => {
    setOpen(false);
  };
  function isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }
  return (
    <>
      {activeTrade ? (
        <CoreChart />
      ) : (
        <div>
          {loading ? (
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              minHeight="90vh"
            >
              <CircularProgress />
              <Typography variant="h6" style={{ marginTop: "16px" }}>
                Loading trading signals...
              </Typography>
            </Box>
          ) : signals.length > 0 ? (
            <Box>
              {/* <Typography variant="h6" style={{ margin: "16px 0" }}>
            Trading signals loaded successfully!
          </Typography> */}
              <Grid container spacing={2}>
                {signals.map((signal) => (
                  <Grid item xs={12} sm={6} md={4} key={signal.id}>
                    <TradeCard investment={signal} onInvest={handleSubmit} />
                  </Grid>
                ))}
              </Grid>
            </Box>
          ) : (
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              height="90vh"
            >
              <Typography variant="h6">
                No trading signals available at the moment.
              </Typography>
            </Box>
          )}
          <Modal
            aria-labelledby="spring-modal-title"
            aria-describedby="spring-modal-description"
            open={open}
            onClose={handleBuySignal}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                TransitionComponent: Fade,
              },
            }}
          >
            <Fade in={open}>
              <Box sx={style}>
                <Typography id="spring-modal-title" variant="h6" component="h2">
                  Buy {signal?.symbol}
                </Typography>

                <TextField
                  label="Amount"
                  name="amount"
                  value={amount}
                  onChange={(e) => {
                    const value = e.target.value;
                    const regex = /^[0-9]*\.?[0-9]*$/;
                    if (regex.test(value) || value === "") {
                      setAmount(value);
                    }
                  }}
                  fullWidth
                  size="small"
                  sx={{ marginTop: 1, marginBottom: 1 }}
                />

                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "end",
                    marginTop: 2,
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={handleClose}
                    color="secondary"
                    sx={{ mr: 2 }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={handleSubmit}
                    color="primary"
                  >
                    Deposit
                  </Button>
                </Box>
                {error && (
                  <Box mb={2} mt={2}>
                    <Alert severity="error">{error}</Alert>
                  </Box>
                )}
              </Box>
            </Fade>
          </Modal>
        </div>
      )}
    </>
  );
};

export default SignalsPage;
