import React from "react";
import {
  Box,
  Typography,
  Avatar,
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  Divider,
  IconButton,
} from "@mui/material";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { useDispatch, useSelector } from "react-redux";
import { Logout, MoreHoriz, Settings } from "@mui/icons-material";
import { logOut } from "../store/actions/auth";
import { useNavigate } from "react-router-dom";
function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    const randomizedValue = (value + Math.floor(Math.random() * 256)) % 256;

    color += `00${randomizedValue.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}

const User = ({}) => {
  const { user } = useSelector((st) => st.user);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const openMenu = Boolean(anchorEl);
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleProfile = () => {
    navigate("settings");
    handleMenuClose();
  };
  const handleProfileLogout = () => {
    dispatch(logOut());
    handleMenuClose();
  };
  return (
    <>
      {" "}
      <Box component={Button} onClick={handleMenuOpen}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderRadius: 1,
            bgcolor: "background.paper",
            boxShadow: 1,
            padding: 1, // Apply padding to the Button itself
          }}
        >
          <Avatar
            {...stringAvatar(`${user.firstname} ${user.lastname}`)}
            sx={{ mr: 1 }}
          />
          <Box sx={{ display: "flex", flexDirection: "column", ml: 0 }}>
            <Typography variant="body1" color="textSecondary">
              {`${user.firstname} ${user.lastname}`}
            </Typography>
            <Typography variant="body2" color="textPrimary">
              {user.email}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={openMenu}
        onClose={handleMenuClose}
        // onClick={handleMenuClose}

        slotProps={{
          paper: {
            elevation: 4,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              border: "1px solid",
              borderColor: "divider",
              boxShadow:
                "0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)",

              mb: 1.5,

              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "bottom" }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <MenuItem onClick={handleProfile}>
          <Avatar /> Profile
        </MenuItem>
        <MenuItem onClick={handleProfileLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>{" "}
    </>
  );
};

export default User;
