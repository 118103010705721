import {
  Alert,
  Backdrop,
  Box,
  Button,
  Card,
  Grid,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSpring, animated } from "@react-spring/web";
import mobile from "../assets/mobile.png";
import mpesa from "../assets/mpesa.png";
import { TextFields } from "@mui/icons-material";
import ApiService from "../lib/api_service";
import { convertToKsh } from "../lib/convertAmount";
import { useSelector } from "react-redux";

const Fade = React.forwardRef(function Fade(props, ref) {
  const {
    children,
    in: open,
    onClick,
    onEnter,
    onExited,
    ownerState,
    ...other
  } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {React.cloneElement(children, { onClick })}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element.isRequired,
  in: PropTypes.bool,
  onClick: PropTypes.any,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
  ownerState: PropTypes.any,
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const api = new ApiService();
export default function WithdrawFundsModal({ open, handleClose }) {
  const [method, setMethod] = useState(0);
  const [step, setStep] = useState(1); // To track the current step
  const [agents, setAgents] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [amount, setAmount] = useState("");
  const [mpesaCode, setMpesaCode] = useState("");
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const { user } = useSelector((st) => st.user);

  // Function to handle method selection
  const handleDSetMethod = (val) => {
    setMethod(val);
    setStep(2);
  };

  // Fetch agents from API (mocked)
  useEffect(() => {
    if (step === 2 && method === 1) {
      // Simulate API call
      const fetchAgents = async () => {
        // Replace with your actual API call
        const response = await api.getRequest("api/v1/agents", user.token, {
          type: "withdraw",
        });
        const data = await response.data;
        console.log("DATA", data);
        setAgents(data);
      };
      fetchAgents();
    }
  }, [step, method]);

  // Function to move to the next step
  const handleNextStep = async () => {
    if (step < 3) {
      if (step === 2 && !selectedAgent) {
        setError("Please select an agent before you continue");
        setTimeout(() => {
          setError(null);
        }, 4000);
        return;
      } else {
        setStep(step + 1);
      }
    } else {
      // Final submission logic here
      if (!selectedAgent) {
        setError("Please select an agent");
        setTimeout(() => {
          setError(null);
        }, 4000);
        return;
      }
      if (amount.length === 0) {
        setError("Amount in USD is required");
        setTimeout(() => {
          setError(null);
        }, 4000);
        return;
      }
      // Handle saving the data
      const res = await api.postRequestwithBearer(
        "api/v1/accounts/withdraw",
        JSON.stringify({
          agentId: selectedAgent.id,
          mpesaCode,
          amountUSD: amount,
        }),
        user.token
      );
      if (!res.status) {
        setError(res.message);
        return;
      }
      setMessage(res.message);
      setTimeout(() => {
        handleClose();
        setMessage(null);
      }, 3000);
    }
  };

  // Function to handle going back to the previous step
  const handlePreviousStep = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };
  return (
    <Modal
      aria-labelledby="spring-modal-title"
      aria-describedby="spring-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          TransitionComponent: Fade,
        },
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            ...style,
            width: { xs: "95%", sm: "80%", md: "25%" }, // Adjust width for mobile
            maxHeight: "90vh", // Prevent modal from overflowing on small screens
            overflowY: "auto", // Enable scrolling on mobile
          }}
        >
          <Typography id="spring-modal-title" variant="h6" component="h2">
            Withdraw Funds
          </Typography>

          {step === 1 && (
            <>
              <Typography id="spring-modal-description" sx={{ mt: 2 }}>
                Choose a withdraw method:
              </Typography>
              <Stack
                direction={{ xs: "row", sm: "row" }} // Stack vertically on mobile
                justifyContent="center"
                alignItems="flex-start"
                spacing={2}
                sx={{
                  width: "100%",
                  marginTop: 2,
                }}
              >
                <Card
                  key={0}
                  variant="outlined"
                  component={Button}
                  onClick={() => handleDSetMethod(0)}
                  sx={{
                    p: 2,
                    width: "100%",
                    background: "none",
                    height: "fit-content",
                    backgroundColor:
                      method === 0 ? "action.selected" : undefined,
                    borderColor: (theme) =>
                      method === 0
                        ? theme.palette.mode === "light"
                          ? "primary.light"
                          : "primary.dark"
                        : theme.palette.mode === "light"
                        ? "grey.200"
                        : "grey.800",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column", // Stack vertically on all screens
                      alignItems: "center",
                      gap: 2.5,
                    }}
                  >
                    <Box
                      component="img"
                      src={mobile} // Replace with the actual path to your image
                      alt="Crypto Deposit"
                      sx={{ width: 70, height: 70, borderRadius: "20%" }}
                    />
                    <Typography
                      color="text.primary"
                      variant="body2"
                      fontWeight="bold"
                    >
                      Cryptocurrency
                    </Typography>
                  </Box>
                </Card>

                <Card
                  key={1}
                  variant="outlined"
                  component={Button}
                  onClick={() => handleDSetMethod(1)}
                  sx={{
                    p: 2,
                    width: "100%",
                    height: "fit-content",
                    background: "none",
                    backgroundColor:
                      method === 1 ? "action.selected" : undefined,
                    borderColor: (theme) =>
                      method === 1
                        ? theme.palette.mode === "light"
                          ? "primary.light"
                          : "primary.dark"
                        : theme.palette.mode === "light"
                        ? "grey.200"
                        : "grey.800",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column", // Stack vertically on all screens
                      alignItems: "center",
                      gap: 2.5,
                    }}
                  >
                    <Box
                      component="img"
                      src={mpesa} // Replace with the actual path to your image
                      alt="Agent Deposit"
                      sx={{ width: 120, height: 70, borderRadius: "0%" }}
                    />
                    <Typography
                      color="text.primary"
                      variant="body2"
                      fontWeight="bold"
                    >
                      Agent
                    </Typography>
                  </Box>
                </Card>
              </Stack>
              {method === 0 && (
                <Box mt={1} mb={1}>
                  <Alert severity="error">
                    Adding Funds by Crypto Coming soon...
                  </Alert>
                </Box>
              )}
            </>
          )}

          {step === 2 && method === 1 && (
            <>
              <Typography id="spring-modal-description" sx={{ mt: 2 }}>
                Select an Agent:
              </Typography>
              <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                sx={{
                  width: "100%",
                  marginTop: 2,
                }}
              >
                {agents.map((agent) => (
                  <Card
                    key={agent.id}
                    variant="outlined"
                    component={Button}
                    onClick={() => setSelectedAgent(agent)}
                    sx={{
                      p: 2,
                      width: "100%",
                      height: "fit-content",
                      background:
                        selectedAgent?.id === agent.id
                          ? "action.selected"
                          : undefined,
                      borderColor:
                        selectedAgent?.id === agent.id
                          ? "primary.light"
                          : "grey.200",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: { xs: "column", md: "row" }, // Stack on mobile
                        alignItems: { md: "center" },
                        gap: 2.5,
                      }}
                    >
                      <Box
                        component="img"
                        src={mpesa} // Replace with the actual path to your image
                        alt="Agent Deposit"
                        sx={{ width: 100, height: 50, borderRadius: "0%" }}
                      />
                      <Typography variant="body2" fontWeight="bold">
                        {`${agent.firstname} ${agent.lastname}`}
                      </Typography>
                      <Typography
                        variant="h6"
                        color="warning.main"
                        fontWeight="bold"
                      >
                        1 USD = {agent.rates} KSh
                      </Typography>
                      <Typography variant="body2" color="text.primary">
                        {agent.mobileNumber}
                      </Typography>
                    </Box>
                  </Card>
                ))}
                {error && (
                  <Box mt={1} mb={1}>
                    <Alert severity="error">{error}</Alert>
                  </Box>
                )}
              </Stack>
            </>
          )}

          {step === 3 && (
            <>
              <Box textAlign="center" p={2}>
                <Typography variant="h6" color="warning.main">
                  Important Information
                </Typography>
                <Typography variant="body1" color="warning.main" mt={2}>
                  Please note that{" "}
                  <span style={{ fontWeight: "bolder" }}> 8% </span>of the
                  amount you withdraw will be deducted as a service fee. Make
                  sure to review your withdrawal amount before proceeding.
                </Typography>
              </Box>
              <Typography id="spring-modal-description" sx={{ mt: 2 }}>
                Enter the amount:
              </Typography>
              <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                sx={{
                  width: "100%",
                  marginTop: 2,
                }}
              >
                <TextField
                  label="Amount in USD"
                  variant="outlined"
                  fullWidth
                  value={amount}
                  onChange={(e) => {
                    const value = e.target.value;
                    const regex = /^[0-9]*\.?[0-9]*$/;
                    if (regex.test(value) || value === "") {
                      setAmount(value);
                    }
                  }}
                />
                {amount.trim() !== "" && (
                  <Alert severity="warning">
                    You will receive{" "}
                    {convertToKsh({
                      agentRate: selectedAgent.rates,
                      amountInUsd: parseFloat(amount) * 0.92,
                    })}{" "}
                    from {selectedAgent.mobileNumber}.<br /> Thank You!
                  </Alert>
                )}
                {message && (
                  <Box mt={1} mb={1}>
                    <Alert severity="success">{message}</Alert>
                  </Box>
                )}
                {error && (
                  <Box mt={1} mb={1}>
                    <Alert severity="error">{error}</Alert>
                  </Box>
                )}
              </Stack>
            </>
          )}

          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              marginTop: 2,
            }}
          >
            {step > 1 && (
              <Button
                variant="outlined"
                onClick={handlePreviousStep}
                sx={{ marginRight: 4 }}
              >
                Back
              </Button>
            )}
            <Button
              variant="outlined"
              onClick={handleNextStep}
              disabled={step === 1 && method === 0}
            >
              {step === 3 ? "Withdraw" : "Next"}
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}
