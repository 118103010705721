import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ApiService from "../lib/api_service";
import StatusChip from "./StatusChip";
import UserAvatar from "./UserAvatar";

const api = new ApiService();
export default function LatestReferrals() {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [loading, setLoading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const { user } = useSelector((st) => st.user);
  const fetchData = async (page, pageSize, searchTerm) => {
    setLoading(true);
    try {
      const response = await api.getRequest(
        "api/v1/referrals/referees",
        user.token,
        {
          page: page + 1,
          limit: pageSize,
          search: searchTerm,
        }
      );
      console.log("DATA");
      setData(response.data);
    //   setTotalRows(response.data);
    } catch (error) {
      console.error("Error fetching transactions data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(page, pageSize, searchTerm);
  }, [page, pageSize, searchTerm]);
  const columns = [
    {
      field: "user",
      headerName: "Referred",
      flex: 1,
      minWidth: 200,
      editable: false,
      renderCell: (params) => <UserAvatar user={params.row.user} />,
    },
    {
      field: "amount",
      headerName: "Amount (USD)",
      flex: 1,
      minWidth: 100,
      editable: false,
    },
    {
      field: "status",
      headerName: "Pending",
      editable: false,
      flex: 1,
      minWidth: 120,
    //   renderCell: (params) => <StatusChip status={params.row.status} />,
    },
    {
      field: "updatedAt",
      headerName: "Updated At",
      editable: false,
      flex: 1,
      minWidth: 200,
      renderCell: (params) =>
        DateTime.fromISO(params.row.updatedAt).toLocaleString(
          DateTime.DATETIME_MED
        ),
    },
    {
      field: "createdAt",
      headerName: "Created At",
      editable: false,
      flex: 1,
      minWidth: 200,
      renderCell: (params) =>
        DateTime.fromISO(params.row.createdAt).toLocaleString(
          DateTime.DATETIME_MED
        ),
    },

    // {
    //   field: "actions",
    //   headerName: "Actions",
    //   type: "actions",
    //   flex: 1,
    //   getActions: (params) => [
    //     <GridActionsCellItem
    //       icon={<VisibilityIcon />}
    //       label="View"
    //       onClick={() => handleViewClick(params.row)}
    //     />,
    //     <GridActionsCellItem
    //       icon={<EditIcon />}
    //       label="Update"
    //       onClick={() => handleUpdateClick(params.row)}
    //     />,
    //   ],
    // },
  ];
  return (
    <div style={{ width: "100%" }}>
      <DataGrid
        sx={{ width: "100%" }}
        rows={data}
        columns={columns}
        pagination
        page={page}
        pageSize={pageSize}
        rowsPerPageOptions={[5, 10, 25]}
        rowCount={totalRows}
        paginationMode="server"
        onPageChange={(newPage) => setPage(newPage)}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        loading={loading}
        components={{
          Toolbar: GridToolbar,
        }}
        filterModel={{
          items: [
            {
              columnField: "user.firstname",
              operatorValue: "contains",
              value: searchTerm,
            },
          ],
        }}
        onFilterModelChange={(filterModel) => {
          if (filterModel.items.length > 0) {
            setSearchTerm(filterModel.items[0].value || "");
          } else {
            setSearchTerm("");
          }
        }}
      />
    </div>
  );
}
