import { useTheme } from "@emotion/react";
import { Box, Chip, Typography } from "@mui/material";

const StatusChip = ({ status }) => {
  const theme = useTheme();
  const statusColors = {
    pending: { label: "Pending", color: theme.palette.warning.main },
    completed: { label: "Completed", color: theme.palette.success.main },
    rejected: { label: "Rejected", color: theme.palette.error.main },
  };

  const { label, color } = statusColors[status];

  return (
    <Box
      sx={{
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        px: 1.0,
        py: 0.3, 
        borderRadius: "16px", 
        border: `1px solid ${color}`, 
        color: color, 
        backgroundColor: "transparent", 
        fontSize: "0.875rem",
        fontWeight: 500, 
      }}
    >
      <Typography variant="body2" sx={{ color: color }}>
        {label}
      </Typography>
    </Box>
  );
};

export default StatusChip;
