import React from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  Backdrop,
  IconButton,
} from "@mui/material";
import { animated, useSpring } from "@react-spring/web";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/CloseRounded";
import DeleteIcon from "@mui/icons-material/Delete";

const Fade = React.forwardRef(function Fade(props, ref) {
  const {
    children,
    in: open,
    onClick,
    onEnter,
    onExited,
    ownerState,
    ...other
  } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {React.cloneElement(children, { onClick })}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element.isRequired,
  in: PropTypes.bool,
  onClick: PropTypes.any,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
  ownerState: PropTypes.any,
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 420,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 1,
  borderRadius: 1,
};
const CampaignModal = ({ campaign, onClose }) => {
  return (
    <Modal
      open={campaign != null}
      onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          TransitionComponent: Fade,
        },
      }}
    >
      <Fade in={campaign != null}>
        <Box
          sx={{
            ...style,
            width: { xs: "95%", sm: "80%", md: "25%" }, // Adjust width for mobile
            maxHeight: "90vh", // Prevent modal from overflowing on small screens
            overflowY: "auto",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box></Box>
            {/* <Typography variant="h6" sx={{ textAlign: "center" }}>
              {campaign.title}
            </Typography> */}
            <IconButton aria-label="delete" onClick={onClose} size="small">
              <CloseIcon color="error" fontSize="small" />
            </IconButton>
          </Box>

          <Typography variant="body1">{campaign.description}</Typography>
        </Box>
      </Fade>
    </Modal>
  );
};

export default CampaignModal;
