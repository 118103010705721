import { useTheme } from "@emotion/react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import emptyImage from "../../assets/empty.jpg";
import { updateUser } from "../../store/actions/auth";
import SwitchToggle from "../../components/SwitchToggle";
import ApiService from "../../lib/api_service";
import { useEffect } from "react";

const api = new ApiService();
const UserProfile = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [visible, setVisible] = useState(false);
  const [notificationStatus, setNotificationStatus] = useState(false);
  const [message, setMessage] = useState(null);
  const user = useSelector((st) => st.user).user;
  const dispatch = useDispatch();

  const [password, setPassWord] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const handleChangePassword = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("password", password);
    formData.append("newPassword", newPassword);
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/v1/users/change-password`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
  };

  const handleUploadImage = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("profile-image", file);
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/v1/users/profile-image`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${user.token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    const updatedUser = { ...user, imageUrl: response.data.data.user.imageUrl };
    dispatch(updateUser(updatedUser));
  };
  useEffect(() => {
    fetchNotificationStatus();
  }, []);

  const fetchNotificationStatus = async () => {
    try {
      const res = await api.getRequest("api/v1/accounts/push", user.token);
      if (res.status) {
        setNotificationStatus(res.data.status);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handlePushNotifications = async () => {
    const response = await api.postRequestwithBearer(
      notificationStatus
        ? `api/v1/accounts/push/disable`
        : `api/v1/accounts/push/activate`,
      JSON.stringify({ device_type: "web" }),
      user.token
    );
    if (response.status) {
      setMessage(response.message);
      setNotificationStatus(response.data.status);
      setTimeout(() => {
        setMessage(null);
      }, 3000);
    }
  };

  return (
    <Box
      sx={{
        bgcolor: "background.default",
        p: isMobile ? 0 : 4,
        minHeight: "90vh",
      }}
    >
      <Card
        sx={{
          maxWidth: isMobile ? `100%` : 600,
          mx: "auto",
          mb: 4,
        }}
      >
        <CardHeader
          title={
            <Typography variant="h5" component="h2">
              {`${user.firstname} ${user.lastname}`}
            </Typography>
          }
          subheader={user.email ? `Email: ${user.email}` : "Email not provided"}
        />
        <CardContent>
          <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
            <Avatar
              src={
                user.imageUrl
                  ? `https://shifa-bckt.s3.amazonaws.com/${user.imageUrl}`
                  : emptyImage
              }
              alt="profile"
              sx={{ width: 128, height: 128 }}
            />
            <label htmlFor="profile-image-upload">
              <input
                accept="image/*"
                style={{ display: "none" }}
                id="profile-image-upload"
                type="file"
                onChange={handleUploadImage}
              />
            </label>
          </Box>
          <Divider />
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={6}>
              <Typography variant="body2" color="textSecondary">
                Email
              </Typography>
              <Typography variant="body1">{user.email}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" color="textSecondary">
                Phone
              </Typography>
              <Typography variant="body1">
                {user.mobileNumber ? user.mobileNumber : "N/A"}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card sx={{ maxWidth: 600, mx: "auto", marginBottom: 2 }}>
        <CardHeader
          title={<Typography variant="h6">Notifications</Typography>}
        />
        <CardContent>
          <Box sx={{ display: "flex", px: 2, justifyContent: "space-between" }}>
            <Typography> Push Notifications</Typography>
            {message && (
              <Typography variant="caption" color="success.main">
                {message}
              </Typography>
            )}
            <SwitchToggle
              status={notificationStatus}
              handleBlockToggle={handlePushNotifications}
            />
          </Box>
        </CardContent>
      </Card>

      <Card sx={{ maxWidth: 600, mx: "auto" }}>
        <CardHeader
          title={<Typography variant="h6">Update Password</Typography>}
        />
        <CardContent>
          <form onSubmit={handleChangePassword}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label={"Current Password"}
                  placeholder={"current password"}
                  value={password}
                  type={visible ? "text" : "password"}
                  onChange={(e) => setPassWord(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setVisible(!visible)}
                          edge="end"
                        >
                          {visible ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="New Password"
                  placeholder="new password"
                  type={visible ? "text" : "password"}
                  value={newPassword}
                  required
                  onChange={(e) => setNewPassword(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setVisible(!visible)}
                          edge="end"
                        >
                          {visible ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
              <Button
                component={Link}
                to="/"
                variant="contained"
                color="secondary"
                sx={{ mr: 2 }}
              >
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary">
                Update Password
              </Button>
            </Box>
          </form>
        </CardContent>
      </Card>
    </Box>
  );
};

export default UserProfile;
