import React, { useEffect, useRef, useState } from "react";
import ApexCharts from "apexcharts";
import { Box, useMediaQuery } from "@mui/material";
import { DateTime } from "luxon";
import ApiService from "../lib/api_service";
import { useSelector } from "react-redux";
import { useTheme } from "@emotion/react";

const api = new ApiService();

export default function CoreChart({ data }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const chartRef = useRef(null);
  const [series, setSeries] = useState([
    {
      name: "USD",
      data: [],
    },
  ]);
  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    fetchStockData();

    // const interval = setInterval(() => {
      fetchStockData();
    // }, 120000);
    // return () => clearInterval(interval);
  }, []);

  const fetchStockData = async () => {
    try {
      const response = await api.getRequest("api/v1/stock-data", user.token); // Replace with your API endpoint
      const processedData = processStockData(response.data);
      setSeries((prevSeries) => {
        const updatedData = [...processedData, ...prevSeries[0]?.data]; // Append new data to the start of the old data
        return [{ name: "USD", data: updatedData }];
      });
    } catch (error) {
      console.error("Error fetching stock data", error);
    }
  };
  const processStockData = (data) => {
    return data.map((item) => ({
      x: new Date(item.datetime),
      y: [
        parseFloat(item.open),
        parseFloat(item.high),
        parseFloat(item.low),
        parseFloat(item.close),
      ],
    }));
  };

  const options = {
    chart: {
      type: "candlestick",
      height: isMobile ? 450 : 700,
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
        autoSelected: "selection",
      },
      animations: {
        enabled: true,
        easing: "easeinout",
        speed: 800,
      },
      zoom: {
        enabled: true,
        type: "xy",
        autoScaleYaxis: true,
      },
      selection: {
        enabled: true,
        type: "xy",
        fill: {
          color: "#24292e",
          opacity: 0.2,
        },
        stroke: {
          width: 1,
          color: "#24292e",
          dashArray: 3,
        },
        xaxis: {
          min: undefined,
          max: undefined,
        },
        yaxis: {
          min: undefined,
          max: undefined,
        },
      },
    },
    title: {
      text: "USD",
      align: "left",
      style: {
        fontSize: "18px",
        fontWeight: "bold",
        color: "#0781bd",
      },
    },
    xaxis: {
      type: "datetime",
      labels: {
        format: "MMM dd HH:mm",
        style: {
          colors: "#607D8B",
          fontSize: "12px",
        },
      },
      tooltip: {
        enabled: true,
        formatter: function (val) {
          return DateTime.fromMillis(val).toFormat("MMM dd, yyyy HH:mm");
        },
      },
    },
    yaxis: {
      tooltip: {
        enabled: true,
      },
      labels: {
        formatter: function (value) {
          return value.toFixed(2);
        },
        style: {
          colors: "#607D8B",
          fontSize: "12px",
        },
      },
      title: {
        text: "Price (USD)",
        style: {
          color: "#607D8B",
          fontSize: "14px",
          fontWeight: "bold",
        },
      },
    },
    plotOptions: {
      candlestick: {
        colors: {
          upward: "#26a69a",
          downward: "#ef5350",
        },
        wick: {
          useFillColor: true,
        },
      },
    },
    tooltip: {
      enabled: true,
      shared: true,
      intersect: false,
      style: {
        fontSize: "12px",
      },
      x: {
        show: true,
        format: "MMM dd, yyyy HH:mm",
      },
      y: {
        formatter: function (val) {
          return val.toFixed(2);
        },
        title: {
          formatter: (seriesName) => `${seriesName}: `,
        },
      },
      theme: "dark", // Optionally change the theme to dark
      onDatasetHover: {
        highlightDataSeries: true,
      },
      marker: {
        show: true,
      },
      fixed: {
        enabled: false, // Disable fixed tooltips, allowing them to move freely
        position: "topRight", // If fixed tooltips are enabled, set position
        offsetX: 0,
        offsetY: 0,
      },
    },
    grid: {
      borderColor: "#e0e0e0",
      strokeDashArray: 4,
    },
    legend: {
      show: true,
      position: "top",
      horizontalAlign: "right",
      fontSize: "14px",
      fontWeight: 400,
      labels: {
        colors: "#263238",
      },
    },
  };

  useEffect(() => {
    const chart = new ApexCharts(chartRef.current, {
      ...options,
      series,
    });

    chart.render();

    return () => {
      chart.destroy();
    };
  }, [series]);

  return (
    <Box
      sx={{
        p: isMobile ? 0 : 4,
        bgcolor: "background.paper",
        borderRadius: 1,
        boxShadow: 2,
        // maxWidth: 1200,
        minWidth: isMobile ? 200 : 800,

        mx: "auto",
        marginTop: isMobile ? 2 : 4,
      }}
    >
      <Box ref={chartRef} />
    </Box>
  );
}
