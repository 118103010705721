import { Delete, Edit } from "@mui/icons-material";
import {
  Backdrop,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { animated, useSpring } from "@react-spring/web";
import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ApiService from "../../lib/api_service";
import { red } from "@mui/material/colors";
const Fade = React.forwardRef(function Fade(props, ref) {
  const {
    children,
    in: open,
    onClick,
    onEnter,
    onExited,
    ownerState,
    ...other
  } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {React.cloneElement(children, { onClick })}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element.isRequired,
  in: PropTypes.bool,
  onClick: PropTypes.any,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
  ownerState: PropTypes.any,
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const api = new ApiService();
const AgentManagement = () => {
  const [agents, setAgents] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [openDelete, setOpenDelete] = useState(false); // Modal state
  const [selectedAgent, setSelectedAgent] = useState(null);
  const user = useSelector((st) => st.user).user;
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    mobileNumber: "",
    active: true,
    imageUrl: "",
    rates: 0,
    type: "",
  });

  useEffect(() => {
    fetchAgents();
  }, []);

  const fetchAgents = async () => {
    try {
      const response = await api.getRequest("api/v1/agents");
      setAgents(response.data);
    } catch (error) {
      console.error("Error fetching agents:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    try {
      if (editMode) {
        await api.putRequestwithBearer(
          `api/v1/agents/${selectedAgent.id}`,
          JSON.stringify(formData),
          user.token
        );
      } else {
        await api.postRequestwithBearer(
          "api/v1/agents",
          JSON.stringify(formData),
          user.token
        );
      }
      fetchAgents();
      setOpenModal(false);
      setFormData({
        firstname: "",
        lastname: "",
        email: "",
        mobileNumber: "",
        active: true,
        imageUrl: "",
      });
      setEditMode(false);
    } catch (error) {
      console.error("Error saving agent:", error);
    }
  };

  const handleEdit = (agent) => {
    setSelectedAgent(agent);
    setFormData(agent);
    setEditMode(true);
    setOpenModal(true);
  };

  const handleDelete = async () => {
    try {
      await api.deleteRequest(`api/v1/agents/${selectedAgent?.id}`, user.token);
      fetchAgents();
      handleDeleteModal();
    } catch (error) {
      console.error("Error deleting agent:", error);
    }
  };

  const columns = [
    // { field: "id", headerName: "ID", minWidth: 150 },
    {
      field: "firstname",
      headerName: "First Name",
      flex: 1,
      editable: false,
    },
    {
      field: "lastname",
      headerName: "Last Name",
      flex: 1,
      editable: false,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      editable: false,
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
      editable: false,
    },
    {
      field: "mobileNumber",
      headerName: "Mobile Number",
      flex: 1,
      editable: false,
    },
    {
      field: "active",
      headerName: "Active",
      flex: 1,
      editable: false,
    },
    {
      field: "rates",
      headerName: "Rates",
      flex: 1,
      editable: false,
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      flex: 1,
      editable: false,
      cellClassName: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<Edit />}
          label="Edit"
          onClick={() => handleEdit(params.row)}
        />,
        <GridActionsCellItem
          icon={<Delete />}
          label="Delete"
          onClick={() => handleDeleteModal(params.row)}
        />,
      ],
    },
  ];
  const handleDeleteModal = (agent) => {
    setOpenDelete(!openDelete);
    setSelectedAgent(agent);
  };
  return (
    <div style={{}}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          marginTop: 2,
          marginBottom: 2,
        }}
      >
        {" "}
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpenModal(true)}
        >
          Add Agent
        </Button>
      </Box>
      {/* <Card
        variant="outlined"
        sx={{
          height: "100%",
          width: "100%",
          display: { xs: "none", sm: "flex" },
          pointerEvents: "none",
        }}
      > */}
      <DataGrid
        rows={agents}
        editMode="row"
        pagination
        rowsPerPageOptions={[5, 10, 25]}
        columns={columns}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        sx={{ width: "100%" }}
      />
      {/* </Card> */}

      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box sx={style}>
          <Typography variant="h6" gutterBottom>
            {editMode ? "Edit Agent" : "Add Agent"}
          </Typography>
          <TextField
            fullWidth
            margin="normal"
            label="First Name"
            name="firstname"
            value={formData.firstname}
            onChange={handleInputChange}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Last Name"
            name="lastname"
            value={formData.lastname}
            onChange={handleInputChange}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Mobile Number"
            name="mobileNumber"
            value={formData.mobileNumber}
            onChange={handleInputChange}
          />
          <FormControl
            fullWidth
            size="small"
            sx={{ marginTop: 1, marginBottom: 1 }}
          >
            <InputLabel id="demo-simple-select-label">Type</InputLabel>
            <Select
              name="type"
              labelId="type"
              id="typet"
              value={formData.type}
              label="type"
              onChange={handleInputChange}
            >
              <MenuItem value="deposit">Deposit</MenuItem>
              <MenuItem value="withdraw">Withdraw</MenuItem>
            </Select>
          </FormControl>
          <TextField
            fullWidth
            margin="normal"
            label="Currency Rates"
            type="number"
            name="rates"
            value={formData.rates}
            onChange={handleInputChange}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Image URL"
            name="imageUrl"
            value={formData.imageUrl}
            onChange={handleInputChange}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            sx={{ mt: 2 }}
          >
            {editMode ? "Update" : "Create"}
          </Button>
        </Box>
      </Modal>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={openDelete}
        onClose={handleDeleteModal}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
      >
        <Fade in={openDelete}>
          <Box sx={style}>
            {" "}
            <Typography
              id="spring-modal-title"
              variant="h6"
              component="h2"
              color="error.main"
            >
              Signal Delete
            </Typography>
            <Typography
              id="spring-modal-title"
              variant="subtitle2"
              component="h2"
              color="error.main"
            >
              Are you sure you want to delete?
            </Typography>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "end",
                marginTop: 2,
              }}
            >
              <Button
                onClick={handleDeleteModal}
                color="secondary"
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                onClick={handleDelete}
                color="error"
                variant="outlined"
                sx={{ ml: 2 }}
              >
                Delete
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default AgentManagement;
