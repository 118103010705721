import React from "react";
import { Avatar, Box, Typography } from "@mui/material";

const SignalAvatar = ({ signal }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
        // p: 1,
      }}
    >
      <Avatar
        sx={{
          bgcolor: "success.main",
          width: 50,
          height: 30,
          p: 1,
          ml: 1,
          mr: 1,
        }}
        variant="rounded"
      >
        {signal?.symbol}
      </Avatar>

      <Box
        sx={{
          height: 40,
          display: "flex",
          justifyContent: "start",
          flexDirection: "column",
        }}
      >
        <Typography variant="body2" color="textSecondary">
          {`${signal?.action}, ${signal?.confidenceLevel}`}
        </Typography>
        <Typography variant="caption" color="textPrimary">
          {signal?.timeframe}
        </Typography>
      </Box>
    </Box>
  );
};

export default SignalAvatar;
