import { Telegram, WhatsApp } from "@mui/icons-material";
import { Box, Container, Typography, Button, Fab } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, Navigate, useLocation } from "react-router-dom";
import ApiService from "../../lib/api_service";
import Loader from "../../components/Loader";

const api = new ApiService();
export default function Blocked() {
  const user = useSelector((state) => state.user).user;
  const [isBlocked, setIsBlocked] = useState(true);
  const [loading, setLoading] = useState(false);
  let location = useLocation();

  useEffect(() => {
    if (user.id) {
      checkUserBlockedStatus();
    }
  }, [user.id]);
  const checkUserBlockedStatus = async () => {
    setLoading(true);
    try {
      const response = await api.getRequest(
        `api/v1/accounts/${user.id}/status`,
        user.token
      );

      if (response.status && !response.data.data) {
        setIsBlocked(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching user status", error);
    } finally {
      setLoading(false);
    }
  };
  if (loading) {
    return <Loader />;
  }
  if (!isBlocked) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }
  return (
    <Container
      maxWidth="xs"
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        px: 2,
      }}
    >
      <Box
        component="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          px: 2,
          py: 4,

          width: "100%",
        }}
      >
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          sx={{ fontWeight: "bold", color: "#d32f2f", mb: 2 }}
        >
          Account Blocked
        </Typography>
        <Typography variant="body1" sx={{ textAlign: "center", mb: 3 }}>
          Unfortunately, your account has been blocked due to a violation of our
          community guidelines. We prioritize the safety and respect of all our
          users, and repeated rule violations lead to account restrictions.
        </Typography>

        <Typography
          variant="body2"
          sx={{ color: "gray", textAlign: "center", mb: 3 }}
        >
          If you believe this is a mistake or would like to appeal, please
          contact our support team for further assistance.
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            marginBottom: 2,
            gap: 1,
          }}
        >
          {" "}
          {/* <Fab
            color="primary"
            aria-label="telegram"
            href="https://t.me/freefx_AItrading"
            target="_blank"
          >
            <Telegram />
          </Fab> */}
          <Fab
            color="success"
            aria-label="telegram"
            href="https://wa.link/o0916b"
            target="_blank"
          >
            <WhatsApp />
          </Fab>
        </Box>

        <Typography variant="caption" sx={{ color: "gray" }}>
          Thank you for understanding. We hope to resolve this issue soon.
        </Typography>
        <Button
          color="primary"
          variant="text"
          size="small"
          sx={{
            marginTop: 2,
          }}
          onClick={() => checkUserBlockedStatus()}
        >
          {" "}
          Refresh Status
        </Button>
      </Box>
    </Container>
  );
}
