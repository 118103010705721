import { CreditScore, CurrencyExchange, TaxiAlert } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import { formatCurreny } from "../lib/convertAmount";

export default function WithdrawWidget({ amount }) {
  const netAmount = parseFloat(amount) * 0.92;
  const taxAmount = parseFloat(amount) * 0.08;
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
      }}
    >
      <IconButton>
        <CreditScore />
      </IconButton>
      <Box
        sx={{
          height: 40,
          display: "flex",
          justifyContent: "start",
          flexDirection: "column",
        }}
      >
        <Typography variant="body1" color="textSecondary">
          {formatCurreny({ amount: netAmount, currency: "KES" })}
        </Typography>
        <Typography variant="caption" color="textPrimary">
         Tax:{" "} {formatCurreny({ amount: taxAmount, currency: "KES" })}
        </Typography>
      </Box>
    </Box>
  );
}
