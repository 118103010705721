import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Card,
  Container,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Logo from "../../components/Logo";
import ApiService from "../../lib/api_service";
import { loginUser } from "../../store/actions/auth";

const api = new ApiService();

export default function ChangePassword({}) {
  const [visible, setVisible] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((st) => st.user).user;

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!authenticatePassword(password, confirmPassword)) {
      validatePasswords();
      return;
    }
    const body = JSON.stringify({
      password,
      confirmPassword,
    });
    const tokn = user.token;
    const response = await api.postRequestWithToken(
      "api/v1/auth/change-password",
      body,
      tokn
    );
    if (!response.status) {
      setError(response.message);
      setTimeout(() => {
        setError(null);
      }, 4000);
      return;
    }
    let token = response.data.token;
    const me = response.data.user;
    const usr = { ...me, token: token, isVerified: true };
    dispatch(loginUser(usr));
    setSuccess(response.message);
    navigate("/signin");
  };

  function authenticatePassword(password, confirmPassword) {
    return password === confirmPassword;
  }

  const validatePasswords = () => {
    if (authenticatePassword(password, confirmPassword)) {
      setError("");
      setSuccess("Passwords matched");
    } else {
      setSuccess("");
      setError("Passwords do not match");
    }
  };

  return (
    <Container
      maxWidth="xs"
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        px: 2,
      }}
    >
      <Box
        component="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          px: 2,
          py: 4,
          borderRadius: 2,
          width: "100%",
        }}
      >
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            flexGrow: 1,
            p: 1,
          }}
        >
          <Box mb={2}>
            <Logo />
          </Box>
          <form onSubmit={handleSubmit} style={{ width: "100%" }}>
            <Typography variant="h6" mb={2}>
              Reset your Password
            </Typography>
            <TextField
              variant="outlined"
              label="Password"
              type={visible ? "text" : "password"}
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              fullWidth
              required
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setVisible(!visible)} edge="end">
                      {visible ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              variant="outlined"
              label="Confirm Password"
              type={visible ? "text" : "password"}
              value={confirmPassword}
              onChange={(event) => setConfirmPassword(event.target.value)}
              fullWidth
              required
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setVisible(!visible)} edge="end">
                      {visible ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {error && (
              <Alert severity="error" sx={{ mt: 2 }}>
                {error}
              </Alert>
            )}
            {success && (
              <Alert severity="success" sx={{ mt: 2 }}>
                {success}
              </Alert>
            )}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 3, py: 1.5 }}
            >
              Reset Password
            </Button>
          </form>
        </Card>
      </Box>
    </Container>
  );
}
