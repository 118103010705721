import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import ApiService from "./api_service";
import Loader from "../components/Loader";

const api = new ApiService();
const ProtectedRoute = ({ children, allowedRoles }) => {
  const user = useSelector((state) => state.user).user;
  const [isBlocked, setIsBlocked] = useState(false);
  const [loading, setLoading] = useState(true);
  let location = useLocation();

  useEffect(() => {
    const checkUserBlockedStatus = async () => {
      try {
        const response = await api.getRequest(
          `api/v1/accounts/${user.id}/status`,
          user.token
        );
  
        if (response.status && response.data.data) {
          setIsBlocked(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching user status", error);
      } finally {
        setLoading(false);
      }
    };

    if (user.id) {
      checkUserBlockedStatus();
    }
  }, [user.id]);

  if (loading) {
    return <Loader />;
  }

  if (isBlocked) {
    return <Navigate to="/blocked" state={{ from: location }} replace />;
  }

  if (!user.id) {
    return <Navigate to="/signin" state={{ from: location }} replace />;
  }

  if (!user.isVerified) {
    return <Navigate to="/confirm-otp" state={{ from: location }} replace />;
  }

  if (allowedRoles && !allowedRoles.includes(user.role.name)) {
    if (user.role.name === "ADMIN" || user.role.name === "SUPERADMIN") {
      return <Navigate to="/admin" state={{ from: location }} replace />;
    } else if (user.role.name === "USER") {
      return <Navigate to="/user" state={{ from: location }} replace />;
    }
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
};

export default ProtectedRoute;
