import { Switch } from "@mui/material";
import React from "react";


export default function SwitchToggle({ status, handleBlockToggle }) {
  return (
    <Switch
      checked={status}
      onChange={handleBlockToggle}
      color={status ? "primary" : "secondary"}
      inputProps={{ "aria-label": "block user" }}
    />
  );
}
