import React, { useEffect, useState } from "react";
import { Box, Typography, Chip } from "@mui/material";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import ApiService from "../lib/api_service";
import { useSelector } from "react-redux";
import TradeTimeWidget from "./TradeTimeWidget";

const api = new ApiService();
const TradeWidget = ({}) => {
  const [trade, setActiveTrade] = useState(null);
  const [loading, setLoading] = useState(true);

  const { user } = useSelector((state) => state.user);
  useEffect(() => {
    fetchActiveTrade();
  }, []);

  const fetchActiveTrade = async () => {
    setLoading(true);
    try {
      const response = await api.getRequest(
        "api/v1/plans/active",
        user.token
      );
      if (response.statusCode) {
        if (!isEmpty(response.data)) {
          let data = response.data;
          data.direction = "up";
          setActiveTrade(response.data);
        } else {
          console.log("No active trade found.");
          setActiveTrade(null); // or handle no trade accordingly
        }
      }
    } catch (error) {
      console.error("Error fetching signals data:", error);
    } finally {
      setLoading(false);
    }
  };

  function isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }
  return (
    <Box
      sx={{
        display: { xs: "flex", sm: "flex" },
        alignItems: "center",
        backgroundColor: !trade?.completed
          ? "rgba(0, 0, 0, 0.4)"
          : "rgba(255, 255, 255, 0.4)",
        borderRadius: 1,
        border: "1px solid",
        borderColor: "divider",
        p: 0.5,
        m: 1,
      }}
    >
      {loading ? (
        <>
          {" "}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
              p: 0.5,
              m: 1,
            }}
          >
            <Typography variant="body2" color="textSecondary">
              Checking...
            </Typography>
          </Box>
        </>
      ) : (
        <>
          {" "}
          {trade ? (
            <>
              {" "}
              <Box sx={{ display: "flex", alignItems: "center", mr: 2 }}>
                {trade.direction === "up" ? (
                  <TrendingUpIcon sx={{ color: "success.main" }} />
                ) : (
                  <TrendingDownIcon sx={{ color: "error.main" }} />
                )}
                <Typography variant="body2" sx={{ ml: 1 }}>
                  {!trade.completed ? "Active Trade" : "Inactive"}
                </Typography>
              </Box>
              <Box>
                <Typography variant="body2" color="textPrimary">
                  Amount: Ksh{trade.amount}
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <HourglassTopIcon sx={{ fontSize: 16, mr: 0.5 }} />
                  <TradeTimeWidget
                    trade={trade}
                    refetchData={fetchActiveTrade}
                  />
                </Box>
              </Box>
            </>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "100%",
                p: 0.5,
                m: 1,
              }}
            >
              <Typography variant="body2" color="textSecondary">
                No active trade
              </Typography>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default TradeWidget;
